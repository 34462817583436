import React from "react"
import Grid from "@material-ui/core/Grid"
import { Box } from "@material-ui/core"
import { useStyles } from "../../../../helper/RightbarStyle"
import { useSelector } from "react-redux"

const RightSidebar = ({ opneEditPage }) => {
  const classes = useStyles()
  const clubInfo = useSelector(state => state.userSlice?.user?.userInfo)

  const Contact = [
    {
      day: "Phone",
      date: clubInfo?.clubPhoneCode?.label + "" + clubInfo?.phoneNumber,
    },
    { day: "Email", date: clubInfo?.clubEmail },
    { day: "Website ", date: clubInfo?.clubWebsite },
  ]

  const Days = [
    {
      day: "Monday",
      date:
        `${clubInfo?.openingHours?.monday?.start}-${clubInfo?.openingHours?.monday?.end}` ||
        `09:00-22:00`,
      isChecked: clubInfo?.openingHours?.monday?.isChecked,
    },
    {
      day: "Tuesday",
      date:
        `${clubInfo?.openingHours?.tuesday?.start}-${clubInfo?.openingHours?.tuesday?.end}` ||
        "09:00-22:00",
      isChecked: clubInfo?.openingHours?.tuesday?.isChecked,
    },
    {
      day: "Wednesday ",
      date: `${clubInfo?.openingHours?.wednesday?.start}-${clubInfo?.openingHours?.wednesday?.end}`,
      isChecked: clubInfo?.openingHours?.wednesday?.isChecked,
    },
    {
      day: "Thursday",
      date: `${clubInfo?.openingHours?.thursday?.start}-${clubInfo?.openingHours?.thursday?.end}`,
      isChecked: clubInfo?.openingHours?.thursday?.isChecked,
    },
    {
      day: "Friday",
      date: `${clubInfo?.openingHours?.friday?.start}-${clubInfo?.openingHours?.friday?.end}`,
      isChecked: clubInfo?.openingHours?.friday?.isChecked,
    },
    {
      day: "Saturday",
      date: `${clubInfo?.openingHours?.saturday?.start}-${clubInfo?.openingHours?.saturday?.end}`,
      isChecked: clubInfo?.openingHours?.saturday?.isChecked,
    },
    {
      day: "Sunday",
      date: `${clubInfo?.openingHours?.sunday?.start}-${clubInfo?.openingHours?.sunday?.end}`,
      isChecked: clubInfo?.openingHours?.sunday?.isChecked,
    },
    {
      day: "*Holidays",
      date: `${clubInfo?.openingHours?.holidays?.start}-${clubInfo?.openingHours?.holidays?.end}`,
      isChecked: clubInfo?.openingHours?.holidays?.isChecked,
    },
  ]

  return (
    <>
      <Grid item>
        <Box className={classes.shadowBox}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",

              alignItems: "center",
            }}
          >
            <h4 style={{ fontSize: "14px" }}>Club opening hours</h4>
            <p
              onClick={() => opneEditPage()}
              style={{
                color: "#B4B4BC",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              Edit
            </p>
          </div>
          {Days.map(data => (
            <Box className={classes.day}>
              <h4 className="daytext">{data.day}</h4>
              <h4 className="date">{data.isChecked ? data.date : "Closed"}</h4>
            </Box>
          ))}
        </Box>
        <Box className={classes.shadowBox}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",

              alignItems: "center",
            }}
          >
            <h4 style={{ fontSize: "14px" }}>Contact</h4>
            <p
              onClick={() => opneEditPage()}
              style={{
                color: "#B4B4BC",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              Edit
            </p>
          </div>
          {Contact.map(data => (
            <Box className={classes.day}>
              <h4 className="daytext">{data.day}</h4>
              <h4 className="date">{data.date}</h4>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  )
}

export default RightSidebar
